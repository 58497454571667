import React from 'react';
import logo from './logo.svg';
import './App.css';
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";

const initialSettings = {
  tags: [],
  placeholder: "Type and press enter",
  maxTags: 50,
};

function App() {
  const [settings] = React.useState(initialSettings);
  const [tags, setTags] = React.useState([]);
  const [shaked, toggleShaked] = React.useState(false);
  const [shakedTags, setShakedTags] = React.useState([]);

  function shakeAndListTags() {
    let copiedTags = JSON.parse(JSON.stringify(tags));
    let randomSortedTags = [];
    let listItems = [];
    let sortVariation = [];
    
    while(sortVariation.length < copiedTags.length && sortVariation.length !== 19) {
      randomSortedTags = copiedTags.sort(randomSort);
      listItems = randomSortedTags.join(' ');

      //if(!sortVariation.find(<div>{listItems}</div>)) {
        sortVariation.push(<div>{listItems}</div>);
      //}
    }

    debugger
    
    setShakedTags(sortVariation);

    toggleShaked(true);
  }

  function randomSort() {
    return 0.5 - Math.random();
  }

  return (
    <div className="App">
      <header className="App-header">
        <h1>Shake A Tag!</h1>
        <p>
          Write your tags in the following input field and shake them through!
        </p>

        <ReactTagInput 
          {...settings}
          tags={tags} 
          onChange={(newTags) => setTags(newTags)}
        />

        <button className="shake-btn" onClick={shakeAndListTags}>Shake it!</button>

        {shaked && 
          <>
            <label>Results</label>
            <div>{shakedTags}</div>
          </>
        }
        

        <img src={logo} className="App-logo" alt="logo" />
        
      </header>
    </div>
  );
}

export default App;
